import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { query } from '../../styles/mediaQuery';
import { sharedCard } from '../Offer/OfferItems/OfferItemLayout.styles';

export const SubHeader = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 1.8rem;
  font-weight: 400;
  margin: 2.4rem 0 0;
`;

export const Articles = styled.div`
  margin-top: 5.4rem;
  margin-bottom: 7.2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;

  a {
    text-decoration: none;
  }

  @media ${query.desktop} {
    display: grid;

    grid-template-columns: 1fr 1fr;
  }
`;

export const WaveBigWrapper = styled.div`
  position: absolute;
  left: -50rem;
  top: 30rem;
`;

export const ArticleWrapper = styled.div`
  ${sharedCard};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 2.4rem;
  font-weight: 800;
  margin: 0;
  line-height: 3.6rem;
`;

export const CreatedTime = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};
  opacity: 50%;
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  line-height: 2.8rem;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2.8rem;
  margin: 2.4rem 0 3.6rem;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: auto;
`;
