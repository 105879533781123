import React from 'react';
import { theme } from '../../../styles/theme';

interface ArrowRightIconProps {
  color?: string;
  width?: string;
  height?: string;
}

const ArrowRightIcon = ({ color, width, height }: ArrowRightIconProps) => {
  return (
    <svg
      width={width ?? '24'}
      height={height ?? '18'}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5462 8.04385L15.8571 0.394968C15.3277 -0.131656 14.4706 -0.131656 13.9664 0.394968C13.437 0.921591 13.437 1.77427 13.9664 2.27584L19.3865 7.66768H1.33617C0.60506 7.66768 0 8.26958 0 8.99687C0 9.72416 0.60506 10.3261 1.33617 10.3261H19.3613L13.9664 15.7179C13.437 16.2445 13.437 17.0972 13.9664 17.5988C14.2185 17.8496 14.5714 18 14.9244 18C15.2773 18 15.605 17.8746 15.8824 17.5988L23.5967 9.92481C23.8488 9.67402 24 9.32291 24 8.97179C23.9495 8.64573 23.7983 8.29463 23.5462 8.04385H23.5462Z"
        fill={color ?? theme.color.primaryOrange}
      />
    </svg>
  );
};

export default ArrowRightIcon;
