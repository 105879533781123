import React from 'react';
import { SimpleArticle } from '../../pages/blog';
import * as S from './ArticlesList.styles';
import SectionWrapper from '../Common/SectionWrapper';
import LinkButton from '../Common/Buttons/LinkButton';
import { useTranslation } from 'react-i18next';
import WaveBigIcon from '../Common/Icons/WaveBigIcon';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

interface ArticlesListProps {
  articles: SimpleArticle[];
}

const ArticlesList = ({ articles }: ArticlesListProps) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <SectionHeaderText>{t('blog_header1')}</SectionHeaderText>
      {/* TODO: add some text - its lorem ipsum for now */}
      {/* <S.SubHeader>{t('blog_header2')}</S.SubHeader> */}
      <S.Articles>
        <S.WaveBigWrapper>
          <WaveBigIcon />
        </S.WaveBigWrapper>
        {articles.map((art) => (
          <S.ArticleWrapper key={art.id}>
            <S.Title>{art.title}</S.Title>
            <S.CreatedTime>{art.createdAt.toLocaleDateString()}</S.CreatedTime>
            <S.Description>{art.description}</S.Description>
            <S.BottomSection>
              <LinkButton to={`${art.slug}_${art.contentfulId}`}>
                {t('blog_list_more_btn')}
              </LinkButton>
            </S.BottomSection>
          </S.ArticleWrapper>
        ))}
      </S.Articles>
    </SectionWrapper>
  );
};

export default ArticlesList;
