import React from 'react';
import { theme } from '../../../styles/theme';

interface WaveBigIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const WaveBigIcon = ({ color, width, height }: WaveBigIconProps) => {
  return (
    <svg
      width={width ?? 1538}
      height={height ?? 213}
      viewBox="0 0 1538 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.06"
        d="M1050.55 212.396C1029.34 212.396 1009.38 204.131 994.334 189.072L873.512 68.1837C867.352 62.0202 857.342 62.0202 851.252 68.1837L733.159 186.341C703.199 216.388 650.768 216.388 620.807 186.341L506.215 71.7557C500.055 65.5922 490.045 65.6622 483.954 71.7557L370.062 185.71C339.122 216.668 288.651 216.738 257.71 185.71L142.348 70.2148C138.358 66.2225 133.668 65.5923 131.218 65.5923C128.768 65.5923 124.077 66.2225 120.087 70.2148L54.3562 135.982C41.896 148.449 21.7354 148.449 9.34518 135.982C-3.11506 123.515 -3.11506 103.344 9.34518 90.9466L75.0766 25.1793C105.037 -4.86777 157.468 -4.86777 187.429 25.1793L302.791 140.675C308.951 146.838 318.961 146.768 325.051 140.675L438.944 26.7202C469.884 -4.23741 520.355 -4.30744 551.296 26.7202L665.888 141.375C669.878 145.367 674.568 145.998 677.018 145.998C679.468 145.998 684.158 145.367 688.148 141.375L806.241 23.2182C837.181 -7.7394 887.652 -7.7394 918.593 23.2182L1039.42 144.107C1043.41 148.099 1048.1 148.729 1050.55 148.729C1053 148.729 1057.69 148.099 1061.68 144.107L1181.94 23.7785C1211.97 -6.2686 1264.33 -6.19856 1294.29 23.7785L1410.56 140.115C1414.55 144.107 1419.24 144.737 1421.69 144.737C1424.14 144.737 1428.83 144.107 1432.82 140.115L1483.64 89.2656C1496.1 76.7986 1516.26 76.7986 1528.65 89.2656C1541.12 101.733 1541.12 121.904 1528.65 134.301L1477.83 185.15C1447.87 215.197 1395.44 215.197 1365.48 185.15L1249.21 68.814C1245.22 64.8218 1240.53 64.1914 1238.08 64.1914C1235.63 64.1914 1230.94 64.8218 1226.95 68.814L1106.69 189.142C1091.71 204.131 1071.76 212.396 1050.55 212.396Z"
        fill={color ?? theme.color.primaryOrange}
      />
    </svg>
  );
};

export default WaveBigIcon;
