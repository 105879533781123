import React from 'react';
import { MainLayout } from '../components/MainLayout';
import { SEO } from '../components/SEO';
import routes from '../static/constants/routes';
import { graphql } from 'gatsby';
import HeaderBlog from '../components/Blog/HeaderBlog';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import ArticlesList from '../components/Blog/ArticlesList';
import BookPromotionBanner from '../components/BookPromotionBanner';
import { seoData } from '../static/seoData';

export interface SimpleArticle {
  id: string;
  title: string;
  locale: string;
  contentfulId: string;
  slug: string;
  createdAt: Date;
  description: string;
}

const Blog = ({ data }) => {
  const { i18n } = useI18next();
  const activeLang = i18n.resolvedLanguage;

  const articles: SimpleArticle[] = data?.allContentfulArticle?.nodes
    ?.map((art) => {
      const article: SimpleArticle = {
        title: art.title,
        slug: art.slug,
        id: art.id,
        contentfulId: art.contentful_id,
        locale: art.node_locale,
        createdAt: new Date(art.createdAt),
        description: art.description,
      };

      return article;
    })
    .filter((art: SimpleArticle) => art.locale === activeLang);

  return (
    <MainLayout>
      <BookPromotionBanner />
      <HeaderBlog />
      <ArticlesList articles={articles} />
    </MainLayout>
  );
};

export default Blog;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.blog[lang];

  return (
    <SEO
      title={metaData.title}
      description={metaData.description}
      pathname={routes.blog}
    />
  );
};

export const localesQuery = graphql`
  query LocalesQuery($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allContentfulArticle(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        id
        node_locale
        contentful_id
        slug
        createdAt
        description
      }
    }
  }
`;
