import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.button};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-transform: uppercase;
  padding: 0;
`;

interface LinkButtonProps {
  to: string;
  children: React.ReactNode;
}

const LinkButton = ({ to, children }: LinkButtonProps) => {
  return (
    <Link to={to}>
      <Wrapper>
        <Button>{children}</Button>
        <ArrowRightIcon />
      </Wrapper>
    </Link>
  );
};

export default LinkButton;
