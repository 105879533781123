/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { query } from '../../../styles/mediaQuery';

export const sharedCard = css`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};

  @media ${query.desktop} {
    padding: 4rem;
  }
`;

export const Wrapper = styled.div`
  scroll-margin-top: 1rem;
`;

export const OfferTitle = styled.div`
  background-color: ${({ theme, $isOpen }) =>
    $isOpen ? theme.color.textSecondary : theme.color.primaryOrange};
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
  cursor: pointer;
  position: relative;

  @media ${query.tablet} {
    padding: 2.4rem;
  }

  @media ${query.desktop} {
    padding: 3rem 5rem;
    background-color: ${({ theme, $isOpen }) =>
      $isOpen ? theme.color.textSecondary : theme.color.primaryOrangeDim};

    &::before {
      content: '';
      display: ${({ $isOpen }) => ($isOpen ? 'none' : 'block')};
      position: absolute;
      width: 2rem;
      height: 100%;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.color.primaryOrange};
      border-radius: 5px 0 0 5px;
    }

    &:hover {
      background-color: ${({ theme, $isOpen }) =>
        $isOpen ? theme.color.textSecondary : theme.color.primaryOrange};
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
`;

export const Number = styled.p`
  display: none;

  @media ${query.desktop} {
    display: block;
    color: ${({ theme }) => theme.color.backgroundPrimary};
    font-family: ${({ theme }) => theme.font.family.archivo};
    font-size: ${({ theme }) => theme.font.size.headingMedium};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    margin: 0;
    position: relative;
    user-select: none;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.color.backgroundPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
  position: relative;
  user-select: none;

  @media ${query.desktop} {
    font-family: ${({ theme }) => theme.font.family.archivo};
    font-size: ${({ theme }) => theme.font.size.headingMedium};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }
`;

export const ArrowIcon = styled.img`
  transform: ${({ $isOpen }) => ($isOpen ? '' : 'rotate(180deg)')};
  width: 30px;
  height: 15px;
  margin-left: 1.6rem;
`;

export const Content = styled.div`
  max-height: ${({ $isOpen }) => ($isOpen ? '15000px' : '0')};
  padding: ${({ $isOpen }) => ($isOpen ? '2rem 0 2rem' : '0')};
  overflow: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  transition: all 0.35s ease;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media ${query.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
`;

export const Card = styled.div`
  ${sharedCard};
  margin-top: 2.8rem;
  margin-bottom: 4rem;

  @media ${query.desktop} {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 3rem;
  }
`;

export const ContentTheoryPart = styled.div`
  ${sharedCard};
  padding-top: 2.4rem;

  @media ${query.desktop} {
    padding-top: 9rem;
  }
`;

export const ContentPracticalPart = styled.div`
  ${sharedCard};

  padding-top: 2.4rem;

  @media ${query.desktop} {
    padding-top: 9rem;
  }
`;

export const ContentTitle = styled.h3`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.paragraphBig};
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  margin: 0 0 2rem;
`;

export const ContentDescription = styled.div`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0;
  line-height: 2.8rem;

  li {
    list-style-type: disc;
    margin-left: 40px;
  }
`;

export const ContentDescriptionSpan = styled.span`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const ContentDescriptionListItem = styled.li`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0;

  list-style-type: disc;
  margin-left: 40px;
`;

export const ContentIcon = styled.img`
  width: 72px;
  display: block;
  margin: 0 auto 1.6rem;

  @media ${query.desktop} {
    position: absolute;
    left: 4rem;
    top: -5rem;
  }
`;

export const BtnWrapper = styled.div`
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  button {
    width: 100%;
  }

  @media ${query.desktop} {
    a,
    button {
      width: auto;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  display: none;
  width: 100%;

  @media ${query.desktop} {
    display: block;
  }
`;
